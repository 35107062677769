<template>
   <Layout id="campaign">
      <PageHeader :title="title" :items="items"></PageHeader>
      <template v-if="hasData">
         <div style="height: 200vh">
            <iframe
               :src="getAnalytics"
               frameborder="0"
               width="100%"
               height="100%"
               scrolling="no"
            ></iframe>
         </div>
      </template>
      <template v-if="noData">
         <div class="no_content">
            <div style="display: grid">
               <img src="../../../../public/noData.svg" alt="" />
               <span class="no_content_text">No Content Available</span>
            </div>
         </div>
      </template>
   </Layout>
 </template>
 
 <script>
 import Layout from "../../layouts/main";
 import PageHeader from "@/components/page-header";
 import MixinRequest from "../../../mixins/request";
 import campaignMixin from "../../../mixins/ModuleJs/campaign";
 
 export default {
   mixins: [MixinRequest, campaignMixin],
   data() {
      return {
         title: "Analytics",
         items: [
            {
            text: "Home",
            href: "/",
            },
            {
            text: "List",
            },
         ],
      };
   },
   components: {
      Layout,
      PageHeader,
   },
 };
 </script>
 